<template>
  <div
    class="card card-custom bgi-no-repeat gutter-b card-stretch"
    style="background-position: right top; background-size: 30% auto; background-image: url(media/svg/shapes/abstract-4.svg)"
  >
    <!--begin::Body-->
    <router-link to="/typetest">
      <div class="card-body">
        <a
          href="#"
          class="card-title font-weight-bold text-muted text-hover-primary font-size-h5"
        >Question Bank</a
        >
        <div class="font-weight-bold text-success mt-9 mb-5">{{ jumlahData.length }} types of questions</div>
        <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">
          Add and Manage Questions easily
        </p>
      </div>
    </router-link>
  </div>
</template>

<script>
import { Fetch_MTypeTest} from "@/core/services/store/mTypeTest.module";
export default {
  name: "Widget14",
  data() {
    return {
      data_TypeTest: 0,
      jumlahData: []
    };
  },

  methods: {
    async getMasterTypeTest() {
      // Tambahkan async dan await di sini
      await this.$store.dispatch(Fetch_MTypeTest);
      this.jumlahData = this.$store.state.mTypeTest.master_TypeTest;
    },
  },

  async mounted() {
    // Panggil getMasterTypeTest secara async
    await this.getMasterTypeTest();
  }
};
</script>
