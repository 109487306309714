<template>
  <div
    class="card card-custom bgi-no-repeat gutter-b card-stretch"
    style="background-position: right top; background-size: 30% auto; background-image: url(media/svg/shapes/abstract-5.svg)"
  >
    <!--begin::body-->
    <router-link to="/education">
    <div class="card-body">
      <a
        href="#"
        class="card-title font-weight-bold text-muted text-hover-primary font-size-h5"
        >Education</a
      >
      <div class="font-weight-bold text-success mt-9 mb-5">{{ jumlahData.length }} Education</div>
      <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">
        Add and Manage Education
      </p>
    </div>
  </router-link>
    <!--end::Body-->
  </div>
</template>

<script>
import { Fetch_MEducation} from "@/core/services/store/mEducation.module";
export default {
  name: "Widget17",
  data() {
    return {
      data_Education: 0,
      jumlahData: []
    };
  },

  methods: {
    async getMasterEducation() {
      // Tambahkan async dan await di sini
      await this.$store.dispatch(Fetch_MEducation);
      this.jumlahData = this.$store.state.mEducation.master_Education;
    },
  },

  async mounted() {
    // Panggil getMasterEducation secara async
    await this.getMasterEducation();
  }
};
</script>
